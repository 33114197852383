._category-filter {
  padding: 10px 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @media screen and (max-width: 990px) {
    position: absolute;
    z-index: 10000;
    background-color: white;
    top: 125px;
    transform: translateX(-200%);
    // transform: translateX(0);
    transition: all 0.5s ease-in-out;
    &.active {
      transform: translateX(0);
    }
  }
}
._category-page-search-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    display: flex;
    align-items: center;
  }
  &__search {
    // width: 70%;
    gap: 10px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 12px 16px;
    flex-grow: 1;
    flex-basis: 70%;
    cursor: pointer;
    input {
      border: none;
      outline: none;
      width: 100%;
      font-size: 18px;
    }
  }
  &__sort {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    flex-grow: 1;
    div {
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: space-between;
      span {
        color: #333;
        font-weight: bolder;
      }
      select {
        padding: 12px 16px;
        border: 1px solid #eaecf0;
        border-radius: 8px;
        font-size: 18px;
        outline: none;
      }
    }
    @media screen and (max-width: 990px) {
      flex-grow: 1;
      justify-content: space-between;
      margin-top: 20px;
      
    }
  }
  &__filter {
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
    gap: 5px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
._ADS_CatPage {
  background-image: url('../../../public/images/ADS_CatPage.png');
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
  margin-bottom: 25px;
}

._cat-page-breadcrumb {
  background-color: #F9FAFB;
}
._category-title {
  width: 100%;
  word-break: break-word;
}
