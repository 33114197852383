.step-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  // padding-top: 50px;
  // padding-bottom: 30px;
}

.step-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #b3b3b3;
}

.step-item.active .step-circle {
  background-color: #000;
  color: #fff;
}

.step-item.completed .step-circle {
  background-color: #4caf50;
  color: #fff;
}

.step-item.inactive .step-circle {
  background-color: #e0e0e0;
  color: #000;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.step-label {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.step-line {
  width: 50px;
  height: 2px;
  background-color: #d0d5dd;
}
@media (max-width: 480px) {
  .step-container {
    padding: 5px;
    gap: 5px;
    justify-content: space-between;
    gap: 0;
    .step-circle {
      height: 25px;
      width: 25px;
      font-size: 12px;
    }
    .step-item{
      gap: 5px;
    }
    span {
      font-size: 12px;
      width: fit-content;
    }
    .step-line {
      // width: 12px;
      width: 0;
    }
  }
}

@media screen and (max-width: 414px) {
  .step-container {
    padding: 5px;
    gap: 5px;
    justify-content: space-between;
    // min-width: 410px;
    width: 90vw;
    gap: 0;
    .step-circle {
      height: 25px;
      width: 25px;
      font-size: 12px;
    }
    .step-item{
      gap: 5px;
    }
    span {
      font-size: 12px;
      width: fit-content;
    }
    .step-label {
      display: none;
    }
    .step-line {
      display: none;
    }
  }
}