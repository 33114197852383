@import './common/variables';
@import './common/functions';
@import './common/text-line-clamp';
@import './common/mixins';
@import './common/reset';

@import './components/header';
@import './components/header-articles';
@import './components/footer';
@import './components/carousel';
@import './components/banner-article';
@import './components/article';
@import './components/loading';
@import './components/toastify';
@import './components/loader';
@import './components/article-detail';
@import './components/modals';
@import './components/404';
@import './components/breadcrumb';
@import './components/rate';
@import './components/modal';
@import './components/gallery';
@import './components/common';
@import './components/venues';
@import './components/pagination';
@import './components/venue';
@import './components/drawer';
@import './components/newsletter.scss';
@import './components/share-social';
@import './components/pin-review.scss';
@import './components/back-listing-detail.scss';
@import './components/step-listing-detail.scss';
@import './components/import-google.scss';
@import './components/basic-infomation.scss';
@import "./components/analyticListing.scss";
@import "./components/ld_sidebar.scss";
@import "./components/ld_detail_content.scss";
@import "./components/company-user-settings.scss";

@import './pages/homepage';
@import './pages/destination';
@import './pages/listing';
@import './pages/dashboard';
@import './pages/authentication';
@import './pages/category-tag-articles';
@import './pages/destination-articles-listings';
@import './pages/profile';
@import './pages/privacy-policy';
@import './pages/listing-edit';
@import './pages/profile-reward';
@import './pages/category-page';

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../styles/fonts/nunito-sans/NunitoSans10pt-Regular.woff2')
      format('woff2'),
    url('../../styles/fonts/nunito-sans/NunitoSans10pt-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../styles/fonts/nunito-sans/NunitoSans10pt-Medium.woff2')
      format('woff2'),
    url('../../styles/fonts/nunito-sans/NunitoSans10pt-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../styles/fonts/nunito-sans/NunitoSans10pt-SemiBold.woff2')
      format('woff2'),
    url('../../styles/fonts/nunito-sans/NunitoSans10pt-SemiBold.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../styles/fonts/nunito-sans/NunitoSans10pt-Bold.woff2')
      format('woff2'),
    url('../../styles/fonts/nunito-sans/NunitoSans10pt-Bold.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.title-row {
  .btn-viewall {
    font-size: convert-rem(18);
    color: $cyan0CC;
    width: auto;
    line-height: 24px;
    font-weight: 700;
    transition: all 0.3s ease;
    padding: 12px 0;
    border-radius: 8px;
    .fa {
      margin-left: 6px;
    }

    &:hover {
      background: $cyan0CC;
      color: $whiteFFF;
      padding: 10px;
    }
    &:focus {
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  @media screen and (max-width: 991px) {
    .btn-viewall {
      font-size: convert-rem(16);
      line-height: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    .btn-viewall {
      font-size: convert-rem(14) !important;
      padding: 5px 0;
      &:hover {
        padding: 5px 0;
        background: transparent;
        color: $cyan0CC;
      }
    }
  }
}
.white-row {
  background: $whiteFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  h1 {
    font-size: convert-rem(46);
    color: $gray556;
    margin-top: 10px;

    @media screen and (max-width: 767px) {
      font-size: convert-rem(32);
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }
}
.desti-img {
  width: 100%;
  height: 412px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px;
    color: $whiteFFF;
    flex-flow: column;
    text-shadow: 0 0 10px $black000, 0 0 10px $black000, 0 0 0 $whiteFFF,
      0 0 0 $black000, 0 0 0 $black000, 0 0 0 $whiteFFF, 0 0 0 $whiteFFF,
      0 0 0 $whiteFFF;
    -moz-text-shadow: 0 0 10px $black000, 0 0 10px $black000, 0 0 0 $whiteFFF,
      0 0 0 $black000, 0 0 0 $black000, 0 0 0 $whiteFFF, 0 0 0 $whiteFFF,
      0 0 0 $whiteFFF;
    -webkit-text-shadow: 0 0 10px $black000, 0 0 10px $black000, 0 0 0 $whiteFFF,
      0 0 0 $black000, 0 0 0 $black000, 0 0 0 $whiteFFF, 0 0 0 $whiteFFF,
      0 0 0 $whiteFFF;

    &:hover {
      text-decoration: none;
    }
    span {
      font-size: convert-rem(14);
    }
    h3 {
      font-size: convert-rem(20);
      margin-top: 16px;
    }
  }
}
.btn-teal {
  font-size: convert-rem(16);
  line-height: 24px;

  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  color: $whiteFFF;
  background: $cyan00C;

  &:hover {
    background: $cyan24D;
    color: $whiteFFF;
  }
}
.just-fb {
  width: 316px !important;
  @media screen and (max-width: 767px) {
    width: 370px !important;
  }
  @media screen and (max-width: 424px) {
    width: 325px !important;
  }
}

.animated.faster {
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@keyframes fadeInUp {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.scroll-top-btn {
  width: 48px;
  height: 48px;

  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9999;

  color: $whiteFFF;
  background: rgba(122, 122, 122, 0.25);
  text-align: center;
  vertical-align: middle;
  font-size: convert-rem(30);
  line-height: 45px;
  border-radius: 50%;

  cursor: pointer;
  transition: all 0.25s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  display: none;
  svg {
    margin-bottom: 4px;
  }
  &:hover {
    background: rgba(122, 122, 122, 0.6);
  }

  @media screen and (max-width: 767px) {
    bottom: 75px;
  }
}

.page-max {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.empty-content {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    width: 100%;
    color: $gray98A;
    font-size: convert-rem(20);
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    height: 180px;
    .message {
      font-size: convert-rem(16);
      line-height: 24px;
    }
  }
}
