.login-modal {
  .modal-dialog {
    max-width: 570px;
  }
  .modal-title {
    font-size: convert-rem(32);
    line-height: 38px;
    padding-bottom: 5px;
    padding-top: 0;
    text-align: center;
    margin: 20px 0 0 0;
    color: $black333;
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 0 110px;

    .btn {
      font-size: convert-rem(16);
      font-weight: 600;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 8px;
      color: $whiteFFF;
      img {
        margin-right: 5px;
      }
    }
    .btn-fb {
      background: $blue3B5;
    }
    .btn-google {
      background: $blue428;
    }
    .btn-email {
      background: $cyan0CC;
      &.disabled {
        background: $gray999;
      }

      &:hover {
        background: $cyan24D;
      }
    }
  }
  .form-control {
    height: 45px;
    font-size: convert-rem(16);
    color: $black333;
    outline: none;
    box-shadow: none;
    text-align: left;
    border: 1px solid $gray666;
    border-radius: 8px;
    padding: 6px 16px;
    line-height: 20px;

    &:focus {
      border: 1px solid $cyan0CC;
    }
  }
  .terms-text {
    font-size: convert-rem(14);
    color: $gray8F8;
    margin-bottom: 20px;
    text-align: center;
  }
  .terms-text a,
  .signup-cont span {
    color: $cyan0CC;
    font-weight: 700;
    cursor: pointer;
  }
  .terms-text span:hover,
  .signup-cont span:hover {
    text-decoration: underline;
  }
  .pass-strength {
    display: flex;
    align-items: center;
    color: $gray6D7;
    font-size: convert-rem(14);
    font-family: 'Open Sans', sans-serif;
    .strength-blk {
      width: 160px;
      height: 8px;
      background-color: rgb(242, 242, 240);
      position: relative;
      margin-right: 8px;
    }
  }
  .alert {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: convert-rem(16);
  }

  .signup-cont {
    color: $black333;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;

    width: 100%;
    font-size: convert-rem(16);
    line-height: 22px;
  }
  .email-sent {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin: 0 auto;
    padding: 24px 0;
    &__title {
      font-size: convert-rem(32);
      line-height: 38px;
      padding-bottom: 5px;
      padding-top: 0;
      text-align: center;
      margin: 20px 0 0 0;
      color: $black333;
    }
    &__description {
      text-align: center;
      font-size: convert-rem(16);
      @media screen and (max-width: 992) {
        font-size: convert-rem(14);
      }
    }
  }

  .err-msg {
    display: inline-block;
    width: 100%;
    color: $redC33;
    font-family: 'Open Sans', sans-serif;
    font-size: convert-rem(14);
    line-height: 18px;
    padding-left: 0;
    margin-top: 3px;
    text-align: left;
  }
  .border-danger {
    border-color: $redC33;
  }

  @media screen and (max-width: 767px) {
    .btn-wrap {
      padding-left: 0;
      padding-right: 0;
    }

    .modal-title {
      font-size: convert-rem(24);
      line-height: 29px;
    }

    .modal-header p {
      font-size: convert-rem(14);
      line-height: 19px;
      margin-top: 0;
    }

    .btn-wrap .btn {
      font-size: convert-rem(14);
    }

    .terms-text {
      font-size: convert-rem(12);
    }

    .signup-cont {
      font-size: convert-rem(14);
    }
  }
}
.modal-header {
  position: relative;
  flex-flow: column;
}

.modal-header .close {
  position: absolute;
  right: 18px;
  top: 15px;
  opacity: 1;
}

.modal-header p {
  font-size: convert-rem(18);
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: $black333;
  margin-top: 8px;
  width: 100%;
}

.pass-visible {
  position: relative;
}

.pass-visible .visibility-icon {
  position: absolute;
  top: 12px;
  right: 10px;
  color: $gray6D7;
  img.off {
    width: 20px;
    cursor: pointer;
  }
  &:hover {
    text-decoration: none;
  }
  .fa-eye-slash,
  &.on .fa-eye {
    display: none;
  }
  &.on .fa-eye-slash,
  .fa-eye {
    display: block;
  }
}

.pass-tag {
  color: $gray8C8;
  line-height: 18px;
  margin: 0 0 10px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: convert-rem(14);
  line-height: 18px;
  color: $gray919;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  color: $gray757;
  font-family: 'Open Sans', sans-serif;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $cyan0CC;
      border-color: $cyan0CC;
    }
    &:checked ~ .checkmark:after {
      border-color: $whiteFFF;
      opacity: 1;
    }
    &:disabled ~ .checkmark:after {
      border-color: $grayC7C;
      opacity: 0.6;
    }
  }
  .checkmark:after {
    left: 5.5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid $whiteFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .terms-link {
    color: $cyan0CC;
    font-family: 'Open Sans', sans-serif;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $whiteFFF;
  border: 1px solid $gray666;
  border-radius: 3px;

  &:after {
    content: '';
    position: absolute;
    opacity: 0.45;
  }
}

.abucket-modal {
  .buck-post {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .img-wrap {
      background-size: cover;
      width: 100px;
      height: 100px;
      border-radius: 8px;
      background-position: 50%;
      padding: 0 !important;
      display: inline-block;
      vertical-align: middle;
    }
    &-cont {
      width: calc(100% - 100px);
      display: inline-block;
      padding-left: 20px;
      .category {
        background: rgba(153, 153, 153, 0.2);
        color: $black333 !important;
        font-size: convert-rem(14);
        font-weight: 600;
        line-height: 18px;
        border-radius: 8px;
        padding: 4px 9px;
        display: inline-block;
      }
      h4 {
        color: $black333;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        font-size: convert-rem(16);
        line-height: 22px;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
  .save-bucket {
    .form-group {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .form-control {
      width: 67%;
      height: 48px;
      border: 1px solid $gray999;
      border-radius: 7px;
      font-weight: 600;
    }
    .btn-teal-outline {
      border: 1px solid $cyan00CB;
      color: $cyan0CC;
      font-size: 15px;
      font-weight: 600;
      height: 48px;
      width: 115px;
      &:hover {
        background: $cyan0CC;
        color: $whiteFFF;
      }
    }
    .title {
      text-align: left;
      font-size: convert-rem(16);
      color: $black333;
      margin-bottom: 15px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      display: inline-block;
    }

    .btn-pink {
      background: $pinkF20;
      border-radius: 8px;
      margin: 10px 0 5px;
      outline: none;
      display: inline-block;
      width: 100%;
      text-align: center;
      height: 48px;
      color: $whiteFFF;
      font-size: convert-rem(18);
      font-weight: 600;
    }
  }
  .modal-dialog {
    max-width: 440px;
  }

  @media screen and (max-width: 767px) {
    .buck-post .img-wrap {
      width: 70px;
      height: 70px;
    }
    .buck-post-cont {
      width: calc(100% - 70px);
      padding-left: 16px;
      .category {
        font-size: 10px;
        padding: 2px 6px;
        border-radius: 4px;
      }
      h4 {
        margin-top: 6px;
        font-size: convert-rem(12);
        line-height: 16px;
      }
    }
    form.save-bucket {
      .btn-teal-outline {
        font-size: 13px;
        font-weight: 600;
        width: 105px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.share-social-modal {
  .modal-content {
    border-radius: 16px;
  }

  .article {
    &-content {
      padding: 16px;
      border: 1px solid rgba(208, 213, 221, 0.3);
      border-radius: 12px;
      h5 {
        font-size: convert-rem(16);
      }
      h4 {
        font-size: convert-rem(18);
        margin-bottom: 0;
      }
      span {
        font-size: convert-rem(14);
        font-weight: 700;
        color: $cyan00C;
      }
    }

    &-share {
      margin-top: 16px;
      justify-content: center;
      .react-share__ShareButton {
        margin-right: 16px;
      }

      .ss-btn {
        width: 40px;
        height: 40px;
        font-size: 18px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &-copy {
      margin-top: 16px;
      margin-bottom: 8px;
      @include flex_center(center, center);
      gap: 8px;

      &__url {
        flex: 2;
        padding: 6px 12px;
        border: 1px solid rgba(234, 236, 240, 1);
        border-radius: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__btn {
        @include flex_center(center, center);
        gap: 8px;
        padding: 6px 12px;
        background: $green7AD;
        color: $whiteFFF;
        border-radius: 8px;
      }
    }
  }
}

.profile-modal {
  .modal-dialog {
    max-width: 660px;

    .modal-content {
      border-radius: 20px;
      border: none;
    }

    .modal-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 24px;
      border-bottom: 1px solid #eaecf0;

      .modal-title {
        font-size: 20px;

        .modal-header-action {
          .modal-header-action-back {
            cursor: pointer;
          }
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }

      .modal-header-action {
        display: flex;
        align-items: center;
        gap: 16px;

        a {
          font-size: 16px;
          color: $green7AD;
          font-weight: bold;
        }

        button {
          background: transparent;
          padding: 0;
          border: none;
          cursor: pointer;
        }
      }
    }

    .modal-body-list-wrapper {
      width: 100%;

      .slider-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 384px;
        margin-left: auto;
        margin-right: auto;

        .slider-controls {
          display: flex;
          align-items: center;

          #rangeSlider {
            width: 320px;
          }
        }

        .slider-controls button {
          background-color: transparent;
          color: red;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        background: #e0e0e0;
        border-radius: 5px;
        outline: none;
        margin: 0 10px;
      }

      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #009688;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }

      .slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #009688;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }

      .slider-value {
        font-size: 16px;
        color: #333;
      }
    }

    .modal-body-crop-avatar {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .modal-body-crop-avatar-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 12px;

        .modal-body-crop-avatar-button-cancel {
          padding: 10px 16px !important;
          background-color: transparent;
          border: 1px solid $grayD0D;
          color: $black344;
          border-radius: 8px;
          font-size: 14px;
          cursor: pointer;
          min-width: 100px;
          font-weight: 500;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .modal-body-crop-avatar-button-save {
          padding: 10px 16px !important;
          background-color: $green009;
          color: $whiteFFF;
          border-radius: 8px;
          font-size: 14px;
          cursor: pointer;
          border: none;
          border: 1px solid $green009;
          min-width: 100px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          &-loading {
            width: 16px;
            height: 16px;
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }

    .modal-body-avatar-image-crop,
    .modal-body-avatar-image {
      border-radius: 50%;
      width: 240px;
      height: 240px;
      overflow: hidden;
      border: 8px solid transparent;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: 767px) {
        width: 200px;
        height: 200px;
      }
    }

    .modal-body-avatar-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 24px;

      .cropper-modal {
        background-color: $grayF9F;
        opacity: 1 !important;
      }

      .cropper-crop-box,
      .cropper-view-box,
      .cropper-face {
        border-radius: 50% !important;
      }

      .cropper-view-box {
        outline: 8px solid white !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 12px;
      }
      .cropper-face {
        background-color: transparent !important;
      }

      .cropper-dashed,
      .cropper-line,
      .cropper-point,
      .cropper-center {
        display: none;
      }

      .modal-body-avatar-image-crop {
        width: 240px;
        height: 240px;
        border-radius: 0 !important;
        background: $grayF9F;
        margin-left: auto;
        margin-right: auto;
        border: 0px solid transparent;
      }
    }

    .modal-body {
      .modal-body-profile-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 24px;

        .modal-body-profile-avatar-title {
          font-size: 14px;
          font-weight: 500;
          color: $black101;
        }

        .modal-body-change-avatar-delete {
          font-size: 14px;
          font-weight: 500;
          color: $black667;

          cursor: pointer;
        }

        .modal-body-change-avatar-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 12px;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .modal-body-avatar-description {
          padding: 12px 16px;
          background: $grayF9F;
          border-radius: 16px;
          color: $black101;
          max-width: 415px;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;

          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 12px;
          }
        }

        .modal-body-change-avatar-button {
          padding: 10px 16px !important;
          background-color: transparent;
          border: 1px solid $green009;
          color: $green009;
          border-radius: 8px;
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;

          &:hover {
            background-color: $green009;
            color: $whiteFFF;
          }

          @media screen and (max-width: 767px) {
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
        }
      }
    }
  }
}

.refer-friend-modal {
  .modal-content {
    padding: 24px 40px;
    border-radius: 20px;

    @media screen and (max-width: 767px) {
      padding: 24px 16px;
    }

    .modal-header {
      align-items: flex-start;
      border-bottom: none;
      padding: 0 0 32px 0;
      border-bottom: 2px solid $grayF2F;

      @media screen and (max-width: 767px) {
        padding: 0;
      }

      .modal-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      p {
        color: $black475;

        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 20px;
          text-align: left;
        }
      }
    }

    .modal-body {
      padding: 32px 0 0 0;

      @media screen and (max-width: 767px) {
        padding: 16px 0 0 0;
      }

      .refer-friend-form-group {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 40px;

        @media screen and (max-width: 767px) {
          margin-top: 24px;
          grid-template-columns: 1fr;
          gap: 12px;

          .form-group {
            .d-flex {
              display: flex;
              flex-direction: row;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }

      .form-group {
        .form-label {
          font-weight: 700;
          color: $black101;

          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }

        .d-flex {
          display: flex;
          gap: 8px;

          @media screen and (max-width: 767px) {
            flex-direction: column;
          }

          .btn-refer {
            background-color: $green009;
            color: $whiteFFF;
            border-radius: 8px;
            padding: 8px 16px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            min-width: 108px;
          }
        }

        .form-control {
          border: 1px solid $grayEAE;
          border-radius: 8px;
          padding: 11px 12px;
          font-size: 14px;
          min-height: 40px;

          @media screen and (max-width: 767px) {
            font-size: 12px;
          }

          &::placeholder {
            color: $grayD0D;
          }
        }

        .form-control-link {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 0 12px;
          border: 1px solid $grayEAE;
          border-radius: 8px;
          width: 100%;

          .form-control {
            padding: 0;
            border: none;
          }
        }

        .btn-copy {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border: none;
          background-color: $green009;
          color: white;
          font-weight: 500;
          font-size: 14px;
          padding: 8px 16px;
          border-radius: 8px;

          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }

        .share-social-list {
          display: flex;
          align-items: center;
          gap: 16px;
          color: $whiteFFF;

          .share-social-btn {
            width: 40px;
            height: 40px;
            background-color: $black475 !important;
            border-radius: 100rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 767px) {
              width: 32px;
              height: 32px;
            }

            &:hover {
              opacity: 0.85;
            }
          }
        }
      }
    }
  }
}
.modal-wrapper {
  width: 500px;
  height: 600px;
}

.modal {
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-body {
  padding-top: 10px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}

._manage-reward-modal {
  min-width: 720px;
  @media screen and (max-width: 767px) {
    min-width: 100%;
    height: 100vh;
    max-height: 100vh;
  }
  max-height: 90vh;
  border-radius: 16px;
  padding-bottom: 5px;
  &.EZDrawer__container {
    top: 50px !important;
    right: 10px !important;
  }
  ._manage-reward-layout {
    padding: 16px;
    min-height: 80%;
    display: flex;
    flex-direction: column;
    height: 100%;
    // width: 80%;
    @media screen and (max-width: 767px) {
      width: 100%;
      min-width: 390px;
    }
    ._manage-reward-header {
      button {
        outline: none;
        border: none;
        background: transparent;
        font-size: large;
        font-weight: bolder;
        &:hover {
          cursor: pointer;
          color: #333;
        }
      }
    }
    ._show-reward-context-modal-footer {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      button {
        background-color: white;
        outline: none;
        border-radius: 5px;
        padding: 5px 10px;
        box-shadow: 0 0 0.5px 1px rgba(0, 0, 0, 0.1);
      }
      & > button:first-child {
        border: 1px solid #d0d5dd;
        &:hover {
          background-color: #d0d5dd;
        }
      }
      & > button:last-child {
        color: #009688;
        background-color: white;
        border: 1px solid #009688;
        &:hover {
          background-color: #009688;
          color: white;
        }
      }
    }
  }
  ._show-reward-context-modal-container {
    // height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    max-height: calc(100% - 50px);
    gap: 0;
    & > div:first-child {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 50px);
    }
    ._show-reward-context-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      @media screen and (max-width: 767px) {
        padding: 10px 5px;
        margin-left: -5px;
        margin-right: -5px;
        min-width: 390px;
      }
    }
    ._show-reward-context-modal-reward {
      // height: 620px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      // max-height: 620px;
      max-height: calc(100% - 70px);
      padding: 0;
      padding-right: 5px;
      & > div:first-child {
        overflow-y: auto;
        flex-grow: 1;
        min-height: calc(100% - 110px);
        max-height: calc(100% - 110px);
        padding-right: 5px;
      }
      .carousel-loading {
        margin: 0;
      }
    }
  }
  ._reward-modal-item-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d0d5dd;
    padding-bottom: 16px;
    margin-bottom: 16px;
    ._editBtnAction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100px;
      width: 100px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    ._editBtnAction-small {
      display: none !important;
      align-items: start !important;
      position: relative;
      max-width: 80px;
      width: 70px;
      div {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      @media screen and (max-width: 767px) {
        display: block !important;
      }
    }
    ._reward-modal-item-content-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      div {
        gap: 5px;
      }
      span {
        line-height: 16px;
        font-size: 14px;
      }
      ._reward-modal-item-expired {
        color: red;
        border: 1px solid red;
        background-color: #fff7f7;
        border-radius: 20px;
        padding: 2px 5px;
        @media screen and (max-width: 767px) {
          width: 65px;
        }
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
    }
    button {
      align-items: center;
      text-align: center;
      background-color: white;
      outline: none;
      border-radius: 5px;
      box-shadow: 0 0 0.5px 1px rgba(0, 0, 0, 0.1);
      border: 1px solid #d0d5dd;
      display: flex;
      justify-content: center;
      height: 36px;
      width: auto;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }

  ._edit-reward-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95%;
    width: 100%;
    padding-bottom: 16px;
    ._edit-reward-modal-context {
      flex-grow: 1;
      overflow-y: auto;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: auto;
      height: 90%;
      max-height: 90%;
    }
    ._edit-reward-input {
      &__container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &__date {
          width: 48%;
        }
      }
      &__label {
        flex-shrink: 0;
        span {
          font-weight: bolder;
          color: #333;
        }
      }
      &__value {
        padding: 8px 15px;
        resize: none;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        width: 100%;
        font-size: medium;
        &:focus {
          outline: none;
          border: 1px solid #009688;
        }
        &[type='date'] {
          cursor: pointer;
        }
        &__description {
          min-height: 200px;
          &:hover {
            cursor: text;
          }
        }
        &__thumbnail {
          background-color: #f2f4f7;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 170px;
          padding: 10px 16px;
          gap: 10px;
        }
      }
      &__tier {
        &:hover {
          cursor: pointer;
        }
        &-container {
          display: flex;
          gap: 10px;
          // margin-left: -10px;
          // margin-right: -10px;
        }
        display: flex;
        gap: 10px;
        padding: 10px 15px;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        &.active {
          border: 1px solid #00a7ad;
        }
      }
    }
  }
  input {
    font-size: 18px;
  }
}

.account-modal {
  &.error{
    div {
      color: red
    }
    input {
      border: 1px solid red;
    }
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 16px;
  input {
    border: 1px solid #d0d5dd;
    padding: 12px 16px;
    border-radius: 8px;
    &:focus {
      outline: none;
      border: 1px solid #00a7ad;
    }
  }
  button {
    margin-top: 16px;
    padding: 10px 16px;
    background-color: #00a7ad;
    color: white;
    outline: none;
    border-radius: 12px;
    border: 1px solid #00a7ad;
    &:disabled {
      background-color: #D0D5DD;
      border: 1px solid #D0D5DD;
      cursor: not-allowed;
    }
  }
  &__password {
    span {
      font-weight: bold;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &.error {
        div {
          color: red;
        }
        input {
          border: 1px solid red;
        }
      }
    }
  }
  &__hint {
    margin-top: 8px;
    gap: 0px !important;
  }
}
.delete-account-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 16px;
  div {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    & > button:first-child {
      background-color: white;
      color: #333;
      border: 1px solid #d0d5dd;
      &:hover {
        background-color: #d0d5dd;
      }
    }
    & > button:last-child {
      background-color: #00a7ad;
      color: white;
      border: 1px solid #00a7ad;
      &:hover {
        background-color: #009688;
        color: white;
      }
    }
  }
  button {
    margin-top: 16px;
    padding: 10px 16px;
    border-radius: 12px;
  }
  
}
