.company-user-settings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 600px;
  &__nav {
    display: flex;
    gap: 0.8rem;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div {
        transition: all 0.5s ease-in-out;
        width: 0%;
        height: 4px;
      }
      &.active {
        div {
          width: 100%;
          height: 4px;
          background-color: #00a7ad;
        }
      }
    }
  }
}
.ReactModal__Overlay--after-open {
  z-index: 1001;
}
.user-info-content-container {
  max-width: 800px;
  & > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  input.error {
    border: 1px solid #f04438;
  }
  .user-info-role__select {
    &.error {
      .select__control {
        border: 1px solid #f04438;
      }
    }
  }
  &.error {
    input,
    .select__control {
      border: 1px solid #f04438;
    }
  }
  &__name {
    input {
      outline: none;
      border: 1px solid #d0d5dd;
      padding: 5px 10px;
      border-radius: 5px;
      &:focus {
        border: 1px solid #00a7ad;
        box-shadow: 0 0 0 2px rgba(0, 167, 173, 0.2);
      }
    }
  }
  &__button {
    margin-top: 1.5rem;
    flex-direction: row !important;
    justify-self: flex-end;
    gap: 1rem;
    align-items: center;
    button {
      padding: 10px;
      outline: none;
      border: 1px solid #00a7ad;
      background-color: #00a7ad;
      color: white;
      border-radius: 10px;
      &:hover {
        background-color: #138386;
        color: white;
      }
    }
    & > button:first-child {
      background: white;
      color: #344054;
      border: 1px solid #d0d5dd;
      &:hover {
        background: #f9fafb;
        color: #344054;
      }
    }
  }
}
.company-infor-content {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  &__status {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 10px 0;
    h6 {
      margin: 0;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background-color: #dcf7f5;
      border-radius: 16px;
      padding: 5px 10px;
      color: #009294;
      span {
        color: #009294;
      }
    }
  }
  &__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    & > div {
      flex-grow: 1;
    }
  }
  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }
  .business-text-input {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 1.5rem;
    label {
      font-size: 14px;
      color: #344054;
      font-weight: 500;
      margin: 0;
    }
    input {
      outline: none;
      border: 1px solid #d0d5dd;
      padding: 5px 10px;
      border-radius: 5px;
      &:focus {
        border: 1px solid #00a7ad;
        box-shadow: 0 0 0 2px rgba(0, 167, 173, 0.2);
      }
    }
  }
}
