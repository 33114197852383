header {
  background: $whiteFFF;
  padding: 10px 0;
  position: relative;
  z-index: 9999;
  border-bottom: 1px solid $grayF2F;

  .header-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 18px;

    @media screen and (min-width: 1042px) {
      position: inherit;
      z-index: 9;
    }

    @media screen and (max-width: 1042px) {
      column-gap: 8px;
    }

    @media screen and (max-width: 767px) {
      justify-content: space-between;
      input {
        padding: 0;
      }
      .profile-block {
        // width: 150px;
        gap: 0;
      }
      .search-hdr {
        padding: 0;
        width: 175px;
      }
      flex-wrap: nowrap;
    }
    @media screen and (max-width: 400px) {
      gap: 0;
    }
  }
  .navbar-brand {
    width: 96px;
    flex-shrink: 0;
    height: auto;
    display: block;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 1199px) {
      width: 75px;
      .form-control {
        font-size: convert-rem(16);
      }
    }
  }

  .search-hdr {
    width: 320px;
    flex-shrink: 0;
    display: inline-block;
    .input-group {
      background-color: $whiteFFF;
      border-radius: 8px;
      overflow: hidden;
      border-radius: 12px;
      border: 1px solid $grayD0D;
    }

    .form-control {
      height: 36px;
      font-size: 16px;
      padding-left: 0;
      color: $grayD0D;

      border: none;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      background-color: transparent;
    }

    .input-group-text {
      background: $whiteFFF;
      border: none;
      border-radius: 8px 0 0 8px;
    }

    @media screen and (max-width: 1441px) {
      width: 280px;
      .form-control {
        font-size: convert-rem(16);
      }
    }

    @media screen and (max-width: 1199px) {
      width: 210px;
      .form-control {
        font-size: convert-rem(16);
      }
    }
  }

  .profile-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 18px;

    @media screen and (max-width: 1042px) {
      column-gap: 8px;
    }

    @media screen and (max-width: 993px) {
      column-gap: 16px;
    }
  }

  .btn-login {
    width: auto;
    font-size: convert-rem(16);
    font-weight: 600;
    color: $cyan00C;
    padding: 16px 12px;
    background: rgba(255, 255, 255, 0);
    border-radius: 8px;
    line-height: 20px;
    transition: all 0.13s linear;
    -moz-transition: all 0.13s linear;
    -webkit-transition: all 0.13s linear;

    &:hover {
      color: $cyan0CC;
      background: rgba(255, 255, 255, 0.22);
    }

    .dropdown {
      list-style-type: none;
      padding: 0;
      margin: 0;
      &-toggle {
        display: flex;
        align-items: center;
      }
    }
  }

  .nav-encl {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    width: auto;
    li .nav-link {
      padding: 16px 12px !important;
      margin-right: 32px;
      line-height: 20px;
      font-weight: 600;
      color: $black475;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0);

      transition: all 0.13s linear;
      -moz-transition: all 0.13s linear;
      -webkit-transition: all 0.13s linear;

      display: flex;
      align-items: center;
      gap: 8px;

      .menu-ico {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.22);
      }

      @media screen and (max-width: 1199px) {
        padding: 16px 2px !important;
        .form-control {
          font-size: convert-rem(16);
        }
      }

      @media screen and (max-width: 1042px) {
        padding: 16px 2px !important;
        .menu-text {
          font-size: convert-rem(14);
        }
      }
    }
    .dropdown-toggle:after {
      margin-top: 2px;
    }
    .navbar .nav-item {
      .dropdown-menu {
        display: none;
      }
      &.dropdown .dropdown-menu {
        width: 100%;
        border-radius: 8px;
        min-width: 270px;
        border: none;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
        padding-top: 12px;
        margin-top: 0;

        li > a {
          color: $cyan0CC;
          line-height: 20px;
          border-radius: 4px 4px 0 0;
          cursor: pointer;
          font-size: convert-rem(16);
          font-weight: 600;
          padding: 12px 20px;
          &:hover {
            background: $whiteF2F;
          }
        }
      }
      &:hover .dropdown-menu {
        display: block;
      }
    }

    @media screen and (max-width: 1199px) {
      margin-left: 12px;
    }

    @media screen and (max-width: 767px) {
      position: absolute;
      top: 16px;
      margin-left: 16px;
      left: 0;
      .navbar-toggler {
        padding: 0;
      }
      .navbar-toggler-icon {
        height: auto;
        width: 24px;
        span {
          height: 3px;
          background: $black475;
          width: 100%;
          float: left;
          margin: 2px 0;
        }
      }
      .navbar-collapse {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 9999;
        background: $whiteFFF;
        padding: 12px 0;
        display: block;
        transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        overflow-y: auto;

        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }

      .navbar-collapse.menu-active {
        left: 0;
      }
      li {
        padding: 2px 0;
        border-bottom: 1px solid $grayF2F;
        margin-bottom: 5px;
        margin: 0 20px;

        .nav-link {
          width: 100%;
          color: $black475;
          margin: 0;
          font-weight: 500;
          font-size: convert-rem(14);

          display: flex;
          align-items: center;
          justify-content: space-between;
          .menu-ico {
            width: 20px;
            filter: brightness(0);
            opacity: 0.8;
          }
        }
      }
      .navbar .nav-item {
        &.dropdown {
          .dropdown-menu {
            width: 100%;
            border-top: 1px solid $grayD8D;
            border-bottom: 1px solid $grayD8D;
            min-width: unset;
            border-radius: 0;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;

            li > a {
              font-size: convert-rem(14);
              letter-spacing: 0.8px;
              padding: 8px 20px;
              font-weight: 600;
              &.item-active {
                background-color: $cyan08C;
                a.dropdown-item {
                  color: $whiteFFF;
                }
              }
            }

            .dropdown-divider {
              display: none;
            }
          }
        }
      }
    }
  }
  .language-switcher {
    width: 190px;
    flex-shrink: 0;
    .language-option {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      column-gap: 8px;
      .option-flag {
        flex-shrink: 0;
        position: relative;
        width: 22px;
        height: 22px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .custom-selection {
      &__menu {
        width: 100% !important;
      }
      &__value-container {
        padding: 10px 32px 10px 14px !important;
      }
      &__indicator {
        padding-left: 0;
      }
    }

    @media screen and (max-width: 1480px) {
      width: 120px;
      .custom-selection {
        &__menu {
          width: 100% !important;
        }
        &__value-container {
          padding: 10px 24px 10px 12px !important;
        }
      }
    }
    @media screen and (max-width: 1280px) {
      width: 44px;
      .custom-selection {
        &__control {
          background-color: $cyan00C !important;
        }
        &__value-container {
          padding: 2px 8px !important;
        }
        &__indicator {
          display: none;
        }
      }
      .language-option {
        span.option-label {
          display: none;
        }
      }
    }

    @media screen and (max-width: 786px) {
      width: 44px;
      .custom-selection {
        &__control {
          background-color: $cyan00C !important;
        }
        &__value-container {
          padding: 2px 8px !important;
        }
        &__indicator {
          display: none;
        }
      }
      .language-option {
        span.option-label {
          display: none;
        }
      }
    }
  }

  // .nav-item-logout {
  //   .nav-link {
  //     color: $redD92;
  //   }
  // }

  .navbar-collapse-header {
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid $grayF2F;

    .close-menu {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
    }

    &-profile {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin: 20px 20px 8px 20px;
      border: 1px solid $grayEAE;
      border-radius: 12px;
      padding: 16px;

      .prof-img {
        border-radius: 100rem;
      }

      &-info {
        flex: 1;

        &-name {
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
        }

        &-email {
          font-size: 12px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .navbar-toggler {
    padding: 0;
  }

  @media screen and (max-width: 1199px) {
    .search-hdr {
      .form-control {
        font-size: convert-rem(16);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .search-hdr {
      padding-left: 16px;
    }
    .nav-encl {
      margin-left: 12px;
      margin-top: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 16px 0;
    .navbar-brand {
      width: 76px;
      padding: 0;
    }

    .btn-login {
      background: $whiteFFF;
      color: $cyan00CB;
      font-size: convert-rem(14);
      padding: 6px 8px;

      &.profile-dd {
        background: transparent;
        position: relative;
        padding: 0;
        .prof-img {
          width: 34px;
          height: 34px;
        }
        &:hover {
          .dropdown-menu {
            display: none;
          }
          .mob-profile-menu {
            display: block;
          }
        }
      }
    }

    .nav-encl {
      position: absolute;
      top: 16px;
      margin-left: 16px;
      left: 0;
      .navbar-toggler {
        padding: 0;
      }
      .navbar-toggler-icon {
        height: auto;
        width: 24px;
        span {
          height: 3px;
          background: $black475;
          width: 100%;
          float: left;
          margin: 2px 0;
        }
      }
    }

    .float-search {
      padding: 6px 0;
      height: auto;
      .form-control {
        font-size: convert-rem(16);
      }
    }

    .hero-avatar {
      .avatar-img {
        width: 24px;
        height: 24px;
        position: relative;
      }
      p {
        font-size: convert-rem(12);
      }
    }

    .hero-overlay {
      padding-bottom: 20px;
      h1 {
        font-size: convert-rem(24);
        line-height: 31px;
        margin-bottom: 5px;
      }
    }
  }
}

.float-search {
  width: 100%;
  height: 92px;

  position: fixed;
  top: 0;
  left: 0;
  background-color: $whiteFFF;
  z-index: 9999;
  padding: 20px 0;

  display: flex;
  align-items: center;
  display: none;

  .search-hdr {
    position: relative;
  }
  .form-control {
    border: none;
    height: 50px;
    color: $black333;
    font-size: convert-rem(18);
    line-height: 22px;
    padding-left: 0;
  }
  .input-group {
    width: 85%;
    background-color: $whiteFFF;
    input {
      background-color: transparent;
    }
    &-text {
      background: transparent;
      border: none;
    }
  }
  span.input-close {
    position: absolute;
    right: 20px;
    top: 13px;
    cursor: pointer;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 767px) {
    height: 66px;
    padding: 8px 0;
  }
}

.menu-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  display: none;
}

.avatar-social {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.hero-avatar {
  display: flex;
  align-items: center;

  .avatar-img {
    width: 24px;
    height: 24px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 50%;
    }
  }
  p {
    padding-left: 12px;
    color: $whiteFFF;
    font-size: convert-rem(14);
    font-weight: 700;
    margin-bottom: 0;

    a {
      color: $whiteFFF;
    }
  }
}

.hero-social {
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-wrap: wrap;
}

.btn-login.profile-dd {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 0;

  &.profile-dropdown {
    position: relative;
    display: inline-block;
    color: $black101;

    .prof-img {
      cursor: pointer;
    }

    &:hover .profile-dropdown-content {
      display: block;
    }
  }

  .profile-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border-radius: 12px;
    min-width: 340px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;

    &-body {
      padding: 18px 24px;

      &-item-link {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 12px 0;
        font-weight: 500;
      }

      &-item-link-logout {
        color: #d92d20;
        cursor: pointer;
      }
    }

    &-header {
      padding: 24px;
      border-bottom: 1px solid $grayEAE;
      display: flex;
      align-items: center;
      gap: 12px;

      .prof-img {
        width: 40px;
        height: 40px;
      }

      &-icon {
        padding: 6px;
        border-radius: 100rem;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
      }

      &-info {
        display: grid;
        gap: 4px;
        flex: 1;
      }

      &-name {
        font-size: 14px;
        font-weight: 700;
        color: $black101;
        line-height: 17px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      &-email {
        font-size: 12px;
        color: $black475;
        line-height: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }
  }

  .dropdown-toggle {
    color: $whiteFFF;
  }
  &:hover {
    background: transparent;
    .dropdown-toggle {
      text-decoration: none;
    }
    .dropdown-menu {
      display: block;
    }
  }
  .prof-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .dropdown .dropdown-menu,
  .mob-profile-menu {
    width: 100%;
    border-radius: 8px;
    min-width: 220px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    padding-top: 12px;
    margin-top: 0;
  }
  .mob-profile-menu {
    display: none;
  }
  .dropdown .dropdown-menu a,
  .mob-profile-menu a {
    color: $cyan0CC;
    line-height: 20px;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    font-size: convert-rem(16);
    font-weight: 600;
    padding: 12px 20px !important;
    &:hover {
      background: $whiteF2F;
    }
  }
  .mob-profile-menu {
    position: absolute;
    right: -15px;
    top: 52px;
    z-index: 9;
    background: $whiteFFF;
    min-width: 190px;
    width: 100%;
    border-radius: 0 0 8px 8px;
    padding: 6px 0;
  }
}

.search-wrapper {
  display: inline-block;
  width: 100%;
  background: $whiteFFF;

  margin-top: 4px;
  border-radius: 0 0 8px 8px;
  padding: 16px 0 0;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

  .search-content {
    padding-left: 124px;
  }

  @media screen and (max-width: 1024px) {
    .search-content {
      padding-left: 24px;
    }
  }
}

.search-wrapper .search-item {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 12px;
  align-items: center;
}

.search-wrapper .search-item .img-search {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 20px;
}

.search-wrapper .search-item .img-search img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.search-wrapper .search-item p {
  font-size: 18px;
  line-height: 24px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0;
  width: calc(100% - 68px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-wrapper .title-row {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 16px;
  padding: 12px 0;
  .search-result {
    padding-left: 124px;
  }
}

.search-wrapper .title-row .btn-viewall {
  font-weight: 600;
  font-size: 16px;
}

.search-wrapper a:hover {
  text-decoration: none;
}

.search-wrapper a:hover .search-item p {
  color: $cyan00C;
}

@media screen and (max-width: 767px) {
  .search-wrapper {
    margin-top: 2px;
  }
  .search-wrapper .search-item p {
    font-size: 14px;
    line-height: 19px;
  }
  .search-wrapper .search-item .img-search {
    margin-right: 16px;
  }
  .search-wrapper .title-row .btn-viewall {
    padding: 0;
  }
}

.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  .header-wrapper {
    border-bottom: 1px solid #dfdfdf;
  }
  .header-main {
    height: 106px;
    display: flex;
    align-items: center;
    position: relative;

    column-gap: 36px;
    .logo {
      width: 46px;
      height: 46px;

      flex-shrink: 0;

      border-radius: 50%;
      overflow: hidden;
    }
    .search {
      width: 420px;
      flex-shrink: 0;
      position: relative;
      .search-field {
        width: 100%;
        border-radius: 50px;
        overflow: hidden;

        position: relative;
        background-color: #f2f1fa;

        display: flex;
        align-items: stretch;
        column-gap: 12px;
        padding: 14px 24px;

        .search-input {
          flex-grow: 1;
          height: 18px;
          border: 0;
          padding: 0;
          margin: 0;

          background-color: transparent;
          font-size: convert-rem(16);
          line-height: 18px;
          color: #767494;

          outline: none;
          &:active {
            outline: none;
            box-shadow: none;
          }
        }
        .search-icon {
          flex-shrink: 0;
          width: 18px;
          height: 18px;

          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: #bcbacd;
            width: 18px;
            height: 18px;
          }
        }
      }
      .search-popover {
        width: 100%;
        position: absolute;
        top: calc(100% + 10px);
        z-index: 99;

        border-radius: 8px;
        overflow: hidden;

        background-color: $whiteFFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        .search-results {
          padding: 12px;
          display: flex;
          flex-direction: column;
          column-gap: 12px;

          .result-item {
            .article {
              display: flex;
              align-items: center;
              column-gap: 12px;
              &-thumb {
                width: 48px;
                height: 48px;
                flex-shrink: 0;

                border-radius: 4px;
                overflow: hidden;
              }
              &-title {
                color: #767494;
              }
            }
          }
        }
      }
    }
    .nav {
      flex-grow: 1;
      display: flex;
      align-items: center;

      column-gap: 24px;
      .nav-control {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        column-gap: 32px;
        .nav-languages-switcher {
          width: 120px;
          .language-selector {
            position: relative;
          }
        }
        .nav-locations-switcher {
          width: 72px;
        }
      }
      .header-selector {
        &__control {
          background-color: transparent;
          border: 0;
          border-radius: 0;

          display: flex;
          align-items: center;
          column-gap: 8px;
          box-shadow: none;
          cursor: pointer;
        }
        &__value-container {
          padding: 0;
        }
        &__single-value {
          margin: 0;
          height: 38px;
          color: #5d5a88;

          .language-option {
            display: flex;
            align-items: center;
            column-gap: 12px;

            img {
              flex-shrink: 0;
              width: 26px;
              height: 16px;
            }
            span {
              font-size: convert-rem(16);
              line-height: 18px;
              color: #5d5a88;
            }
          }
        }
        &__indicator-separator {
          display: none;
        }
        &__indicators {
          width: 21px;
          flex-shrink: 0;
          position: absolute;
          top: 50%;
          right: 0;

          transform: translate(0, -50%);
        }
        &__indicator {
          padding: 0;
          svg {
            width: 21px;
            height: 21px;
            color: #5d5a88;
          }
        }
        &__menu {
          z-index: 999;
          min-width: 92px;
          width: max-content;
          border-radius: 0;
          border: 0;
        }
        &__menu-list {
          padding: 0;
        }
        &__option {
          font-size: convert-rem(14);
          color: #5d5a88;
          .language-option {
            display: flex;
            align-items: center;
            column-gap: 8px;
            white-space: nowrap;
            > img {
              flex-shrink: 0;
              width: 26px;
              height: 16px;
            }
            > span {
              display: block;
              flex-grow: 1;
              font-size: convert-rem(14);
            }
          }
          &--is-selected {
            background-color: #767494;
            color: $whiteFFF;
          }
          &:hover:not(&--is-selected) {
            background-color: #f2f1fa;
          }
        }
      }
      .nav-item {
        color: #5d5a88;
        a {
          color: unset;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .nav-auth {
        flex-shrink: 0;
        .auth-not-access {
          display: flex;
          align-items: center;
          column-gap: 24px;
          .auth-btn {
            border-radius: 30px;
            overflow: hidden;
            font-size: 12px;
            line-height: 14px;

            padding: 14px 18px;
            &.login-btn {
              color: #5d5a88;
              border: 1px solid #5d5a88;
              background-color: transparent;
            }
            &.signup-btn {
              color: $whiteFFF;
              background-color: #5d5a88;
              border: 1px solid #5d5a88;
            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  .header-main,
  .header-categories {
    margin: 0 36px;
  }
  .header-categories {
    display: flex;
    align-items: center;
    column-gap: 32px;

    padding: 32px 0;

    .category-item {
      display: flex;
      align-items: center;
      column-gap: 8px;

      font-size: convert-rem(18);
      font-weight: 700;
      line-height: 18px;

      color: #5d5a88;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;

        flex-shrink: 0;
      }
    }
    .category-list {
      display: flex;
      align-items: center;

      column-gap: 32px;

      .category-child {
        flex-shrink: 0;

        position: relative;
        font-size: convert-rem(18);
        color: #5d5a88;
        cursor: pointer;
        svg {
          width: 18px;
        }

        .category-name.active {
          font-weight: 600;
        }

        .category-children {
          width: max-content;
          max-height: 0;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;

          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          z-index: 999;
          background-color: $whiteFFF;
          border-radius: 8px;
          overflow: hidden;
          transition: max-height 0.6s ease-in-out;

          &.opened {
            max-height: 90vh;
          }

          .content {
            padding: 12px;
            max-height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
            overscroll-behavior: none;
          }

          .sub-category {
            font-size: convert-rem(16);
            padding: 12px;
            border-radius: 4px;
            &:hover {
              background-color: #f2f1fa;
            }
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.category-hero {
  width: 100%;
  position: relative;
  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 650px;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.8));
    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-bottom: 56px;

    .content {
      max-width: 1280px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 0 24px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .categories {
        display: flex;
        align-items: center;
        gap: 16px;
        .category-name {
          color: #fff;
          outline: none;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          padding: 3px 12px 6px;
          font-size: 1.125rem;
          line-height: 24px;
          margin-right: 10px;
          font-weight: 700;
          float: left;
          margin-bottom: 5px;
          white-space: nowrap;
        }
      }
      .city-name {
        width: fit-content;
        font-size: convert-rem(48);
        color: $whiteFFF;
        line-height: 58px;
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 786px) {
    &__img {
      min-height: 540px;
    }
    &__overlay {
      padding-bottom: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    &__img {
      min-height: 420px;
    }
    &__overlay {
      padding-bottom: 32px;
      .content .city-name {
        font-size: convert-rem(24);
        line-height: 30px;
      }
    }
  }
}
.eat-the-world-item {
  @media screen and (max-width: 767px) {
    width: fit-content !important;
  }
}

.dropdown {
  &:hover {
    .explore-dropdown {
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    .explore-dropdown {
      position: static;
      width: 80vw;
      min-width: 380px;
      box-shadow: none;
      border: none;
    }
    .explore-container {
      &__toc {
        display: none;
      }
      width: 100%;
      padding: 16px;
      &__content {
        padding: 0;
        min-width: unset;
        &::before {
          content: 'Popular Destinations';
          position: static;
          display: block;
          font-size: 24px;
        }
        &__head {
          flex-direction: column;
          // flex-wrap: nowrap;
          max-height: 150px;
          overflow: auto;
          padding: 16px 0;
          box-sizing: content-box;
          ::-webkit-scrollbar {
            display: none;
          }
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        &__body {
          display: none;
        }
        &__mobile__region {
          display: flex;
          overflow: auto;
          width: 100%;
          padding: 10px 0;
          ::-webkit-scrollbar {
            display: none;
          }
          scrollbar-width: none;
          -ms-overflow-style: none;
          & > div {
            padding: 6px 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0;
            flex-shrink: 0;
            transition: all 0.5s ease;
            cursor: pointer;
            span,
            div {
              transition: all 0.5s ease;
            }
            div {
              width: 150%;
              height: 2px;
              background-color: #d0d5dd;
            }
            &.active {
              span {
                color: #00a7ad;
              }
              div {
                background-color: #00a7ad;
              }
            }
          }
        }
        &__mobile__items {
          margin-top: 5px;
          display: flex;
          flex-direction: column;
        }
        &__mobile__item {
          margin-top: 12px;
          h6 {
            margin: 0;
            cursor: pointer;
          }
          & > div {
            margin-top: 16px;
            flex-wrap: wrap;
            h6 {
              font-weight: 600;
              flex-basis: 20%;
              flex-shrink: 0;
              padding-top: 7px;
            }
            display: flex;
            // align-items: center;
            align-items: flex-start;
            gap: 12px;
          }
        }
      }
      .explore-mobile-item-city {
        padding: 7px 12px;
        border: 1px solid #d0d5dd;
        border-radius: 5px;
        text-transform: capitalize;
        cursor: pointer;
      }
      .explore-mobile-item-region {
        flex-grow: 1;
        width: 100%;
        h6 {
          margin-bottom: 8px;
        }
      }
      .explore-mobile-item {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
  @media screen and (max-width: 464px) {
    .explore-container {
      padding: 0;
      &__content {
        &::before {
          font-size: 20px;
        }
      }
    }
  }
}

.explore-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  right: 0;
  display: none;
}

.explore-container {
  span,
  h4 {
    font-family: 'Onest', sans-serif;
  }
  .text-description {
    span {
      font-size: 14px;
      line-height: 20px;
      color: #475467;
    }
  }
  display: flex;
  max-height: 500px;
  &__toc {
    padding: 24px 8px 24px 24px;
    border-right: 2px solid #f2f2f2;
    min-width: 280px;
    overflow: auto;
    h4 {
      margin: 0;
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    &__item {
      margin: 12px 0;
      padding: 8px 0;

      div {
        display: flex;
        align-items: center;
        gap: 0 12px;
        flex-wrap: wrap;
        padding: 12px 0;
        span {
          width: fit-content;
          cursor: pointer;
          &:hover {
            color: #333;
          }
        }
      }
    }
  }
  &__content {
    padding: 24px;
    min-width: 500px;
    overflow: auto;
    padding-right: 18px;
    &__head {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
    }
    &__item {
      position: relative;
      height: 60px;
      width: 210px;
      overflow: hidden;
      border-radius: 10px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0;
      flex-direction: column;
      cursor: pointer;
      box-shadow: 0 1px 0px 1px rgba(0, 0, 0, 0.3);
      h4,
      span {
        color: white;
        font-size: 16px;
        z-index: 1;
        margin: 0;
        text-transform: capitalize;
      }
      h4 {
        font-weight: bolder;
        font-size: 18px;
      }
    }
    &__body {
      margin-top: 40px;
      h4 {
        font-size: 20px;
        line-height: 20px;
        color: #101828;
      }
      span {
        color: #475467;
      }
      &__detail {
        gap: 12px;
        margin-top: 24px;
      }
      .explore-city {
        flex-wrap: wrap;
        display: flex;
        gap: 5px 15px;
        text-transform: capitalize;
        span:hover {
          color: #333;
          cursor: pointer;
        }
      }
      .explore-region {
        font-weight: bold;
        color: #475467;
        span:hover {
          color: #333;
          cursor: pointer;
        }
      }
      .explore-country {
        h4 {
          font-size: 18px;
          margin: 0;
          cursor: pointer;
          &:hover {
            color: #333;
          }
        }
      }
    }
    &__mobile__region {
      display: none;
    }
    &__mobile__items {
      display: none;
    }
  }
}
