.ld_location_contact-map {
  padding: 24px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 24px;
  box-shadow: 0 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
  // @media screen and (max-width: 768px) {
  //   padding: 16px 0;
  // }
  @media screen and (max-width: 768px) {
    min-width: 350px;
  }
  @media screen and (max-width: 400px) {
    min-width: 100%;
    // padding: 5px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__contain {
    margin-top: 24px;
    box-sizing: border-box;
    display: flex;
    max-width: 850px;
    min-width: 400px;
    border-radius: 10px;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      min-width: 80%;
    }
    .ld_location_map {
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;
      // background-image: url('/images/listingMapBG.png');
      background-size: cover;
      background-position: center;
      flex-grow: 1;
      flex-shrink: 1;
      box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.1);
      button {
        position: absolute;
        margin-top: 50px;
        background-color: white;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        padding: 5px 10px;
        &:hover {
          background: #f5f5f5;
        }
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
        min-width: 320px;
      }
    }
    .ld_location_contact {
      box-sizing: border-box;
      max-width: 360px;
      min-width: 350px;
      display: flex;
      flex-direction: column;
      align-items: start;
      border: 1px solid #e5e5e5;
      border-radius: 12px;
      box-shadow: 0 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
      padding: 16px;
      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          width: 150px;
          box-sizing: border-box;
          padding: 5px 10px;
          margin-top: 10px;
          justify-content: center;
          gap: 10px;
          background-color: white;
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
          &:hover {
            background: #f5f5f5;
          }
          @media screen and (max-width: 600px) {
            padding: 8px 0px;
            // justify-content: space-between;
            gap: 5px;
            width: 110px;
          }
        }
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
        min-width: 320px;
        max-width: none;
      }
    }
  }
  &.minimize {
    margin-top: 30px;
    width: 100%;
    min-width: 350px;
    & > div {
      flex-direction: column;
      gap: 20px;
    }
    .ld_location_map {
      width: 75%;
    }
    .ld_location_contact {
      min-width: 200px;
      width: 75%;

      button {
        padding: 8px 0px;
        // justify-content: space-between;
        gap: 5px;
        width: 110px;
      }
    }
  }
}
.listingDetail__ADS {
  background-image: url('../../../public/images/ads-temp.png');
  background-size: contain;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  margin-bottom: 25px;
}

.listingDetail-content__premind {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    min-width: 350px;
  }
  .listingDetail-premind__content {
    min-width: 330px;
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 160px;
      box-sizing: border-box;
      padding: 10px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > button:first-child {
      border: 1px solid #00a7ad;
      background-color: white;
      color: #009294;
      box-shadow: 0px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      &:hover {
        background: #f5f5f5;
      }
    }
    & > button:last-child {
      background-color: #00a7ad;
      color: white;
      border: 1px solid #009294;
      box-shadow: 1px 0 0 0.5px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      gap: 5px;
      &:hover {
        background: #009294;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .listingDetail-premind__content {
      flex-direction: column;
      gap: 10px;
      button {
        width: 100%;
      }
    }
  }
}

.listingDetail-content__menu {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  margin-bottom: 10px;
  min-width: 350px;
  box-shadow: 0 0 0.5px 0.5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  .listingDetail-menu__content {
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 15px;
    div {
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}

.listingDetail-content__reviews {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  margin-bottom: 20px;
  min-width: 350px;
  box-shadow: 0 0 0.5px 0.5px rgba(0, 0, 0, 0.1);
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 128px;
      height: 36px;
      box-sizing: border-box;
      padding: 8px 14px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #009294;
      background-color: white;
      color: #009294;
      &:hover {
        background: #009294;
        color: white;
      }
    }
  }
  &__filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-bottom: 5px;
    &__star {
      display: flex;
      gap: 20px;
      align-items: center;
      & > div:first-child {
        display: flex;
        h5 {
          font-size: 30px;
          margin: 0;
        }
        div {
          display: flex;
          align-items: end;
        }
        span {
          font-size: 14px;
          color: #667085;
        }
      }
      & > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 0px;
        h5 {
          font-size: 16px;
          font-weight: 700;
          color: #000;
          margin: 0;
        }
        span {
          font-size: 14px;
          color: #667085;
        }
      }
    }
    &__sort {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 20px;
      flex-wrap: wrap;
      button {
        box-sizing: border-box;
        padding: 0px 14px;
        font-size: 14px;
        height: 32px;
        width: fit-content;
        box-sizing: border-box;
        line-height: 20px;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background-color: white;
        color: #475467;
        &:hover {
          background: #009294;
          color: white;
        }
        &.active {
          color: #00a7ad;
          background: #f0fbfa;
          border: 1px solid #00a7ad;
        }
      }
    }
  }
  .review-component {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
    &__header {
      display: flex;
      gap: 0;
      &__avatar {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        gap: 8px;
        box-sizing: border-box;
        & > div:first-child {
          height: 48px;
          width: 48px;
          overflow: hidden;
          border-radius: 50%;
        }
        &__info {
          display: flex;
          flex-direction: column;
          h6 {
            margin: 0;
          }
          & > div {
            display: flex;
            gap: 5px;
            align-items: center;
          }
          span {
            font-size: 14px;
          }
        }
      }
      &__more {
        min-width: 50px;
        position: relative;
        width: max-content;
        flex-basis: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
        }
        button {
          outline: none;
          background: none;
          border: none;
          display: flex;
          justify-items: center;
        }
        &__dropdown {
          position: absolute;
          display: none;
        }
        &__pinned {
          display: flex;
          gap: 5px;
          align-items: center;
          @media screen and (max-width: 480px) {
            & > span:last-child {
              display: none;
            }
          }
        }
      }
    }
    &__content {
      margin-top: 10px;
      &__rating {
        display: flex;
        gap: 10px;
        span {
          font-size: 14px;
        }
        div {
          display: flex;
          align-items: center;
        }
        & > div:first-child {
          width: 160px;
          justify-content: space-between;
        }
        & > div:last-child {
          // width: 170px;
          padding-left: 10px;
          justify-content: flex-end;
          gap: 8px;
          border-left: 1px solid #e5e5e5;
          .view-on-google {
            gap: 5px;
            span {
              text-decoration: underline;
              &:hover {
                color: #00a7ad;
                cursor: pointer;
              }
            }
          }
        }
      }
      &__context {
        &__text {
          margin-bottom: 13px;
          p span {
            color: #009294;
            text-decoration: underline;
            &:hover {
              color: #00a7ad;
              cursor: pointer;
            }
          }
          // &.truncated {
          //   // display: -webkit-box;
          //   // -webkit-box-orient: vertical;
          //   // -webkit-line-clamp: 3;
          //   // overflow: hidden;
          //   // text-overflow: ellipsis;
          //   // white-space: nowrap;
          // }
        }
        .reply-review {
          span {
            color: gray;
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  // .listingDetail-reviews__content {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   margin-bottom: 20px;
  //   .listingDetail-reviews__content--left {
  //     display: flex;
  //     align-items: center;
  //     gap: 10px;
  //     img {
  //       width: 50px;
  //       height: 50px;
  //       border-radius: 50%;
  //     }
  //     .listingDetail-reviews__content--left--info {
  //       display: flex;
  //       flex-direction: column;
  //       gap: 5px;
  //       .listingDetail-reviews__content--left--info--name {
  //         font-size: 16px;
  //         font-weight: 700;
  //         color: #000;
  //       }
  //       .listingDetail-reviews__content--left--info--date {
  //         font-size: 14px;
  //         color: #667085;
  //       }
  //     }
  //   }
  //   .listingDetail-reviews__content--right {
  //     display: flex;
  //     align-items: center;
  //     gap: 10px;
  //     .listingDetail-reviews__content--right--rating {
  //       display: flex;
  //       align-items: center;
  //       gap: 5px;
  //       .listingDetail-reviews__content--right--rating--star {
  //         width: 20px;
  //         height: 20px;
  //       }
  //       .listingDetail-reviews__content--right--rating--number {
  //         font-size: 16px;
  //         font-weight: 700;
  //         color: #000;
  //       }
  //     }
  //     .listingDetail-reviews__content--right--review {
  //       font-size: 16px;
  //       color: #000;
  //     }
  //   }
  // }
}

.listingReward-container {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0 0 0.5px 0.5px rgba(0, 0, 0, 0.1);
  min-width: 350px;
  margin-bottom: 30px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      border: 1px solid #00a7ad;
      background-color: white;
      color: #009294;
      box-shadow: 0px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      max-height: 40px;
      padding: 0 10px;
      &:hover {
        background: #009294;
        color: white;
      }
    }
  }
  .listingNearby-filter__container {
    display: flex;
    gap: 20px;
    padding-bottom: 15px;
    @media screen and (max-width: 468px) {
      flex-wrap: wrap;
    }
    & > div {
      cursor: pointer;
      div {
        width: 0px;
        height: 2px;
        background-color: #00a7ad;
        transition: ease-in-out 0.3s;
      }
      span {
        color: #475467;
      }
      &.active {
        div {
          width: 100%;
        }
        span {
          color: #101828;
        }
      }
      &:hover {
        div {
          width: 100%;
        }
      }
    }
  }
  .listingReward-reward__container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 16px;
    .reward-card__container {
      min-width: 300px;
      min-height: 150px;
      max-width: 320px;
      min-height: 160px;
      background-image: url('/images/reward-card-bg.png');
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      position: relative;
      h6 {
        margin: 0;
      }
      & > div {
        position: absolute;
        top: 14px;
        left: 20px;
        right: 20px;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        hr {
          margin: 10px 0;
        }
      }
      .reward-card__content {
        flex-grow: 1;
        h6,
        span {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h6 {
          margin-top: 5px;
          font-size: 22px;
          color: #475467;
        }
        .reward-content > span:first-child {
          font-size: 17px;
          color: #667085;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.listingNearby-item__container {
  min-width: 220px;
  min-height: 350px;
  max-width: 225px;
  max-height: 350px;
  @media screen and (max-width: 1024px) {
    margin: 0 auto;
  }
  .listingNearby-item__header {
    position: relative;
    &__image {
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      height: 250px;
      width: 100%;
      box-shadow: 0 1px 0.5px 0.5px rgba(0, 0, 0, 0.1);
      img {
        transition: all ease-in-out 0.5s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    ._ld-nearby-abs {
      position: absolute;
      top: 12px;
      font-size: 20px;
      z-index: 9999;
      &-left {
        left: 12px;
        color: white;
        background-color: #00a7ad;
        font-size: 14px;
        padding: 2px 10px;
        border-radius: 30px;
      }
      &-right {
        right: 12px;
        padding: 5px 5px 0px 5px;
        color: black;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #475467;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .listingNearby-item__content {
    margin-top: 10px;
    flex-grow: 1;
    h6 {
      margin: 0;
    }
    &__rating {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      & > div {
        display: flex;
        align-items: center;
      }
      & > div:first-child {
        gap: 5px;
        div {
          display: flex;
          align-items: center;
        }
      }
      & > div:last-child {
        gap: 12px;
        span {
          color: #667085;
        }
        & > span:first-child {
          padding: 0px 10px;
          border: 1px solid #e5e5e5;
          border-radius: 12px;
        }
      }
    }
  }
}

.listingNearby-carousel {
  position: relative;
  width: 100%;
  padding-left: 5x;
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-navigation {
    top: 50%;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    transform: translate(0, -50%);
    padding: 5px;
    z-index: 999;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);
    &:hover {
      cursor: pointer;
    }
    &.swiper-carousel__next {
      right: 0;
    }
    &.swiper-carousel__prev {
      left: 0;
    }
  }
}

.listingDetail__featured {
  h2 {
    margin: 0;
  }
  padding-top: 80px;
  padding-bottom: 110px;
}

._ld-articles {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  padding: 20px 0;
}

._ld-article-container {
  max-width: 385px;
  min-width: 385px;
  @media screen and (max-width: 1024px) {
    max-width: 290px;
  }
  ._ld-articles-image {
    height: 220px;
    width: 100%;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }
  ._ld-articles-content {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
    }
    &__info {
      padding-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      &__tag {
        font-size: 12px;
        border-radius: 8px;
        border: 1px solid black;
        padding: 0px 10px;
        box-shadow: 0 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.listing-sticky-nav {
  display: flex;
  align-items: center;
  height: 50px !important;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 80%;
  }
  .ld_line {
    width: 0%;
    height: 3px;
    background-color: #00a7ad;
    transition: ease-in-out 0.3s;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    color: #475467;
    height: 100%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    &.active {
      color: #000;
      .ld_line {
        width: 100%;
      }
    }
    &:hover {
      .ld_line {
        width: 100%;
      }
      color: #000;
      cursor: pointer;
    }
    @media screen and (max-width: 434px) {
      font-size: 16px;
      display: none;
      &.active {
        display: flex;
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    div {
      border: 1px solid #344054;
      padding: 5px 5px 0 5px;
      border-radius: 5px;
      box-shadow: 0 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
      &:hover {
        cursor: pointer;
      }
    }
  }
}
